import { ThemeList } from '../services/theme';

export enum LocalStorageItem {
  VehicleListAll = 'VehicleListAll',
  groupList = 'groupList',
  operatorList = 'operatorList',
  operatorRegistry = 'operatorRegistry',
  meInformation = 'meInformation',
  tolerances = 'tolerances',
  Auth = 'token',
  Theme = 'App/theme',
}

export enum SessionStorageItem {
  sharedQueryDateTimeAndVehicles = 'sharedQueryDateTimeAndVehicles',
  pageQueryWorkingHours = 'pageQueryWorkingHours',
  vehicleGroupsSelect = 'pageVehicleParkList',
  pageQueryOverview = 'pageQueryOverview',
}

type StoredType =
  | Record<string, unknown>
  | Array<Record<string, unknown>>
  | ThemeList;

// local
export const getLocalStorageItem = <T extends StoredType>(
  itemName: LocalStorageItem,
): T | null => {
  const item = localStorage?.getItem(itemName);
  return item ? JSON.parse(item) : null;
};

export const setLocalStorageItem = <T extends StoredType>(
  itemName: LocalStorageItem,
  value: T,
): void => {
  localStorage.setItem(itemName, JSON.stringify(value));
};

// session
export const getSessionStorageItem = <T extends StoredType>(
  itemName: SessionStorageItem,
): T | null => {
  const item = sessionStorage.getItem(itemName);
  return item ? JSON.parse(item) : null;
};

export const setSessionStorageItem = <T extends StoredType>(
  itemName: SessionStorageItem,
  value: T,
): void => {
  sessionStorage.setItem(itemName, JSON.stringify(value));
};

/**
 * Clear cache
 */
export const clearStorageCache = (): void => {
  for (const localStorageVariable of Object.values(LocalStorageItem)) {
    localStorage.removeItem(localStorageVariable);
  }

  for (const sessionStorageVariable of Object.values(SessionStorageItem)) {
    sessionStorage.removeItem(sessionStorageVariable);
  }
};
