/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EndDate } from '../models/EndDate';
import type { FuelLossTolerance } from '../models/FuelLossTolerance';
import type { IdleTolerance } from '../models/IdleTolerance';
import type { RefuelingTolerance } from '../models/RefuelingTolerance';
import type { StartDate } from '../models/StartDate';
import type { Stats } from '../models/Stats';
import type { StatsEvent } from '../models/StatsEvent';
import type { StatsFuel } from '../models/StatsFuel';
import type { UserId } from '../models/UserId';
import type { VehicleId } from '../models/VehicleId';
import type { WorkingHours } from '../models/WorkingHours';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class StatsService {
  constructor(public readonly http: HttpClient) {}

  /**
   * Working hours export
   * Working hours export
   * @param operatorId
   * @param startDate
   * @param groupBy
   * @param type
   * @param endDate
   * @returns binary OK
   * @throws ApiError
   */
  public workingHoursExport(
    operatorId: Array<UserId>,
    startDate: StartDate,
    groupBy: 'day' | 'week' | 'month',
    type: 'pdf',
    endDate?: EndDate,
  ): Observable<Blob> {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/stats/working_hours/grouped/export',
      query: {
        operator_id: operatorId,
        start_date: startDate,
        end_date: endDate,
        group_by: groupBy,
        type: type,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid operator ID`,
        500: `Server error`,
      },
    });
  }

  /**
   * Grouped working hours
   * Grouped working hours
   * @param operatorId
   * @param startDate
   * @param groupBy
   * @param endDate
   * @returns WorkingHours OK
   * @throws ApiError
   */
  public workingHoursGrouped(
    operatorId: UserId,
    startDate: StartDate,
    groupBy: 'day' | 'week' | 'month',
    endDate?: EndDate,
  ): Observable<Array<WorkingHours>> {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/stats/working_hours/grouped/',
      query: {
        operator_id: operatorId,
        start_date: startDate,
        end_date: endDate,
        group_by: groupBy,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid operator ID`,
        500: `Server error`,
      },
    });
  }

  /**
   * Working hours
   * Working hours
   * @param operatorId
   * @param startDate
   * @param endDate
   * @returns WorkingHours OK
   * @throws ApiError
   */
  public workingHours(
    operatorId: UserId,
    startDate: StartDate,
    endDate?: EndDate,
  ): Observable<WorkingHours> {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/stats/working_hours/',
      query: {
        operator_id: operatorId,
        start_date: startDate,
        end_date: endDate,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid operator ID`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get grouped statistics
   * Get grouped vehicle statistics
   * @param vehicleId
   * @param startDate
   * @param fuelLossTolerance
   * @param refuelingTolerance
   * @param idleTolerance
   * @param groupBy
   * @param endDate
   * @param calculate
   * @returns any OK
   * @throws ApiError
   */
  public statsGrouped(
    vehicleId: VehicleId,
    startDate: StartDate,
    fuelLossTolerance: FuelLossTolerance,
    refuelingTolerance: RefuelingTolerance,
    idleTolerance: IdleTolerance,
    groupBy: 'hour' | 'day' | 'week' | 'month',
    endDate?: EndDate,
    calculate?: Array<'can'>,
  ): Observable<
    Array<
      StatsEvent & {
        stats: StatsFuel;
      }
    >
  > {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/stats/grouped',
      query: {
        vehicle_id: vehicleId,
        start_date: startDate,
        end_date: endDate,
        fuel_loss_tolerance: fuelLossTolerance,
        refueling_tolerance: refuelingTolerance,
        idle_tolerance: idleTolerance,
        calculate: calculate,
        group_by: groupBy,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid vehicle ID`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Export statistics
   * Export vehicle statistics
   * @param vehicleId
   * @param startDate
   * @param type
   * @param endDate
   * @param unitsKm Only applicable to 'xlsx' and 'csv'
   * @returns binary OK
   * @throws ApiError
   */
  public statsExport(
    vehicleId: Array<VehicleId>,
    startDate: StartDate,
    type: 'xlsx' | 'csv',
    endDate?: EndDate,
    unitsKm: boolean = true,
  ): Observable<Blob> {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/stats/export',
      query: {
        vehicle_id: vehicleId,
        start_date: startDate,
        end_date: endDate,
        type: type,
        units_km: unitsKm,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid vehicle ID`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get statistics
   * Get vehicle statistics
   * @param vehicleId
   * @param startDate
   * @param fuelLossTolerance
   * @param refuelingTolerance
   * @param idleTolerance
   * @param endDate
   * @param calculate
   * @returns Stats OK
   * @throws ApiError
   */
  public stats(
    vehicleId: VehicleId,
    startDate: StartDate,
    fuelLossTolerance: FuelLossTolerance,
    refuelingTolerance: RefuelingTolerance,
    idleTolerance: IdleTolerance,
    endDate?: EndDate,
    calculate?: Array<'operators'>,
  ): Observable<Stats> {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/stats/',
      query: {
        vehicle_id: vehicleId,
        start_date: startDate,
        end_date: endDate,
        fuel_loss_tolerance: fuelLossTolerance,
        refueling_tolerance: refuelingTolerance,
        idle_tolerance: idleTolerance,
        calculate: calculate,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid vehicle ID`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Event statistics
   * Get statistics for specific event
   * @param eventId Event ID
   * @param idleTolerance
   * @param fuelLossTolerance
   * @param refuelingTolerance
   * @returns StatsEvent OK
   * @throws ApiError
   */
  public statsEvent(
    eventId: string,
    idleTolerance: IdleTolerance,
    fuelLossTolerance: FuelLossTolerance,
    refuelingTolerance: RefuelingTolerance,
  ): Observable<StatsEvent> {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/stats/{event_id}',
      path: {
        event_id: eventId,
      },
      query: {
        idle_tolerance: idleTolerance,
        fuel_loss_tolerance: fuelLossTolerance,
        refueling_tolerance: refuelingTolerance,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Event doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }
}
